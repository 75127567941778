<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <div>{{ $t("account.security.title") }}</div>
        </v-card-title>
        <v-card-subtitle class="grey--text text--darken-1">{{ $t("account.security.description") }}</v-card-subtitle>
        <v-card-text class="mt-4">
          <v-text-field
            class="mr-2"
            :label="$i18n.t('users.fields.password.title2')"
            :placeholder="$i18n.t('users.fields.password.placeholder2')"
            v-model="form.oldPassword"
            type="password"
            autocomplete="off"
            outlined
            dense
            append-icon="mdi-shield-account-outline"
            @input="delayTouch($v.form.oldPassword)"
            @blur="$v.form.oldPassword.$touch"
            :error-messages="oldPasswordErrors"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            :label="$i18n.t('users.fields.new_password.title')"
            :placeholder="$i18n.t('users.fields.new_password.placeholder')"
            v-model="form.password"
            type="password"
            autocomplete="off"
            outlined
            dense
            append-icon="mdi-shield-account-outline"
            @input="delayTouch($v.form.password)"
            @blur="$v.form.password.$touch"
            :error-messages="passwordErrors"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            :label="$i18n.t('users.fields.confirm_password.title2')"
            :placeholder="$i18n.t('users.fields.confirm_password.placeholder2')"
            v-model="form.confirmPassword"
            type="password"
            autocomplete="off"
            outlined
            dense
            append-icon="mdi-shield-account-outline"
            @input="delayTouch($v.form.confirmPassword)"
            @blur="$v.form.confirmPassword.$touch"
            :error-messages="confirmPasswordErrors"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" :loading="loading.save" text color="primary">{{ $t('btn.update') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  sameAs,
  maxLength
} from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";
export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  data: () => ({
    form: {
      oldPassword: null,
      password: null,
      confirmPassword: null
    },
    loading: {
      save: false
    }
  }),
  validations: {
    form: {
      oldPassword: {
        required
      },
      password: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(50)
      },
      confirmPassword: {
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    getPath() {
      return "users.changePassword";
    },
    getMessages() {
      return {
        200: this.$t("account.security.edit.success"),
        403: true,
        400: true,
        422: error => {
          const self = this;
          if (error.code === "E100") {
            return self.$i18n.t("account.security.errors.E100");
          }
        }
      };
    },
    preSubmit() {
      this.loading.save = true;
    },
    postSubmit() {
      this.loading.save = false;
    },
    getData(form) {
      return {
        password: form.password,
        oldPassword: form.oldPassword
      };
    },
    resetForm() {
      this.$v.form.$reset();
      for (const prop in this.form) {
        this.form[prop] = null;
      }
    }
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.form.oldPassword.$dirty) return errors;

      if (!this.$v.form.oldPassword.required)
        errors.push(this.$i18n.t("users.fields.password.required2"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;

      if (!this.$v.form.password.required)
        errors.push(this.$i18n.t("users.fields.new_password.required"));

      if (!this.$v.form.password.minLength)
        errors.push(
          this.$i18n.t("users.fields.password.min_length", {
            min: this.$v.form.password.$params.minLength.min
          })
        );

      if (!this.$v.form.password.maxLength)
        errors.push(
          this.$i18n.t("users.fields.password.max_length", {
            min: this.$v.form.password.$params.maxLength.max
          })
        );
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirmPassword.$dirty) return errors;

      if (!this.$v.form.confirmPassword.sameAsPassword)
        errors.push(this.$i18n.t("users.fields.confirm_password.invalid"));
      return errors;
    }
  }
};
</script>